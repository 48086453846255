<template>
  <div>
    <div class="orderInfoHead">
      <el-row>
        <el-col :span="12">
          <span>{{ hotelName }}</span
          >>查看日志
        </el-col>
        <el-col :span="12" style="color: #256def; text-align: right"
          ><span @click="goOrderPage" style="cursor: pointer"
            >返回房型管理</span
          ></el-col
        >
      </el-row>
    </div>
    <div class="searchBox">
      <el-row>
        <el-col :span="10">
          <span>操作人</span>
          <el-input
            size="mini"
            style="width: 120px; margin-left: 10px; margin-right: 20px"
            v-model="searchData.operator"
          ></el-input>
          <span>操作时间</span>
          <el-date-picker
            v-model="chooseDate"
            size="mini"
            @change="searchFn"
            type="daterange"
            style="width: 220px; margin-left: 10px"
            range-separator="-"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="4" style="text-align: right">
          <el-button size="mini" @click="searchFn" type="primary"
            >搜索</el-button
          >
          <el-button size="mini" type="primary" plain style="margin-left: 30px">
            <a
              :href="Url"
              size="mini"
              type=""
              style="color: #256ef1"
              @click="orderExport"
              >导出</a
            ></el-button
          >
          <!-- style="border: 1px solid #256ef1; color: #256ef1;height:28px;line-height:28px;width:56px;" -->
        </el-col>
        <el-col :span="10">
          <div style="margin-top: 13px; text-align: right">
            <el-pagination
              hide-on-single-page
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="searchData.pageNumber"
              :pager-count="5"
              :page-sizes="[20, 50, 100, 200]"
              :page-size="searchData.pageSize"
              layout="total, sizes, prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-table :data="roomLogList" style="width: 100%">
      <el-table-column prop="id" label="操作序号" width="200">
      </el-table-column>
      <el-table-column prop="operator" label="操作人" width="200">
      </el-table-column>
      <el-table-column prop="create_time" label="操作时间" width="200">
      </el-table-column>
      <el-table-column prop="remark" label="操作描述"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { roomLogList_api } from "../../apis/room";
import local from "@/utils/local.js";
export default {
  data() {
    return {
      searchData: {
        pageNumber: 1,
        pageSize: 20,
        operator: "",
        hotel_id: "",
        type: "",
        operation_period: "",
        startDate: "",
        endDate: "",
      },
      hotelName: "",
      roomLogList: [],
      chooseDate: [new Date(), new Date()],
      totalCount: 0,
      Url: "",
      token: "",
    };
  },
  created() {
    this.searchData.hotel_id = this.$route.query.id;
    this.hotelName = this.$route.query.hotelName;
    this.getRoomLogList();
    this.token = local.get("tk");
  },
  methods: {
    //查询数据

    async getRoomLogList() {
      this.searchData.startDate = this.initDate(this.chooseDate[0]);
      this.searchData.endDate = this.initDate(this.chooseDate[1]);
      let { code, data } = await roomLogList_api(this.searchData);
      if (code == 0) {
        this.roomLogList = data.rows;
        this.totalCount = data.total;
      }
    },
    searchFn() {
      this.searchData.pageNumber = 1;
      this.getRoomLogList();
    },
    //回房型管理页面
    goOrderPage() {
      this.$router.push({
        path: "/room",
      });
    },
    //订单分页
    handleCurrentChange(val) {
      this.searchData.pageNumber = val;
      this.getRoomLogList();
    },
    handleSizeChange(val) {
      this.searchData.pageSize = val;
      this.getRoomLogList();
    },
    //格式化时间
    initDate(date) {
      date = new Date(date);

      let year = date.getFullYear();
      let mouth = date.getMonth() + 1;
      if (mouth < 10) {
        mouth = "0" + mouth;
      }
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return `${year}-${mouth}-${day}`;
    },
    //导出
    orderExport() {
      if (this.chooseDate[0]) {
        this.searchData.startDate = this.initDate(this.chooseDate[0]);
      }
      if (this.chooseDate[1]) {
        this.searchData.endDate = this.initDate(this.chooseDate[1]);
      }
      this.Url =
        "http://47.108.228.93:8080/otaHotel/operation-log-info/logExportExcel?token=" +
        this.token +
        "&pageNumber=" +
        this.searchData.pageNumber +
        "&pageSize=" +
        this.searchData.pageSize +
        "&operator=" +
        this.searchData.operator +
        "&hotel_id=" +
        this.searchData.hotel_id +
        "&startDate=" +
        this.searchData.startDate +
        "&endDate=" +
        this.searchData.endDate;
    },
  },
};
</script>

<style lang="less" scoped>
.orderInfoHead {
  margin: top 20px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.searchBox {
  padding-left: 20px;
  height: 60px;
  line-height: 60px;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
}
</style>>

